export default [
    {
        user: "nhamann@axteroid.com",
        auto: false,
        subject: "Envía Guía de remisión remitente N° T003 - 9894",
        recipient: [
            "nhamann@axteroid.com"
        ],
        text: "Estimado cliente, PERU VISUAL T.DIAGNOSTIGRX SRL,\n\nAdjunto Guía de remisión remitente N° T003 - 9892, correspondiente al 14 abr. 2023 por un monto de 0 PEN \n\nAtentamente REPRESENTACIONES DECO S.A.C",
        created: "2023-04-17T20:20:36.775046-04:00",
        document: {
            id: "do_UuGPiAAAAYxaE7gr",
            customer: {
                id: "cus_TTMWS2hYdZv2rn6j8L",
                address: "",
                allow_pdf: true,
                allow_xml: true,
                email: "",
                metadata: {},
                name: "CORPORACION BOTICAS PERU S.A.C.",
                phone: null,
                state: "",
                business_activity: "",
                created: "2023-05-23T16:19:06.863111-04:00",
                updated: "2023-05-23T16:19:06.863134-04:00",
                taxpayer: {
                    tax_id: "20515346113",
                    name: "BOTICAS PERU",
                    address: "",
                    state: "",
                    phone: "",
                    email: "",
                    irs_settings: {},
                    country: "PE"
                }
            },
            metadata: {
                recipient: [
                    "rayosxsolution@gmail.com"
                ]
            },
            shipping: {
                address: "",
                state: "",
                carrier_id: "74751609",
                carrier_name: "WILSON GONZALES TOCTO",
                licence_plate: "VRQ318",
                type: "01"
            },
            status: {
                code: "INI",
                detail: "POS file recieved.",
                irs_id: ""
            },
            exchange: {},
            labels: [],
            amount: 0,
            date: "2023-06-19",
            discount: 0,
            discount_scheme: null,
            exempt: 0,
            number: 9892,
            prefix: "T003",
            subtotal: null,
            tax: 0,
            tax_percent: 0,
            total: 0,
            created: "2023-04-17T15:08:05.303521-04:00",
            updated: "2023-04-17T15:08:05.303540-04:00",
            collection_method: null,
            custom_id: null,
            due_date: "2023-04-14",
            imported: false,
            note: null,
            period_start: null,
            period_end: null,
            document_type: "PE09",
            taxpayer: {
                tax_id: "20602170404",
                name: "PERU VISUAL T.DIAGNOSTIGRX SRL",
                address: "",
                state: "",
                phone: "",
                email: "",
                irs_settings: {},
                country: "PE"
            },
            reference_related: []
        }
    },
    {
        user: "nhamann@axteroid.com",
        auto: false,
        subject: "asdfasdfadfsadfasfdfasdf",
        recipient: [
            "nhamann@axteroid.com"
        ],
        text: "Estimado cliente, PERU VISUAL T.DIAGNOSTIGRX SRL,\n\nAdjunto Guía de remisión remitente N° T003 - 9892, correspondiente al 14 abr. 2023 por un monto de 0 PEN \n\nAtentamente REPRESENTACIONES DECO S.A.C",
        created: "2023-04-17T20:06:32.706558-04:00",
        document: {
            id: "do_UuGPiAAAAYxaE7gr",
            customer: {
                id: "cus_TTMWS2hYdZv2rn6j8L",
                address: "",
                allow_pdf: true,
                allow_xml: true,
                email: "",
                metadata: {},
                name: "CORPORACION BOTICAS PERU S.A.C.",
                phone: null,
                state: "",
                business_activity: "",
                created: "2023-05-23T16:19:06.863111-04:00",
                updated: "2023-05-23T16:19:06.863134-04:00",
                taxpayer: {
                    tax_id: "20515346113",
                    name: "BOTICAS PERU",
                    address: "",
                    state: "",
                    phone: "",
                    email: "",
                    irs_settings: {},
                    country: "PE"
                }
            },
            metadata: {
                recipient: [
                    "rayosxsolution@gmail.com"
                ]
            },
            shipping: {
                address: "",
                state: "",
                carrier_id: "74751609",
                carrier_name: "WILSON GONZALES TOCTO",
                licence_plate: "VRQ318",
                type: "01"
            },
            status: {
                code: "INI",
                detail: "POS file recieved.",
                irs_id: ""
            },
            exchange: {},
            labels: [],
            amount: 0,
            date: "2023-06-19",
            discount: 0,
            discount_scheme: null,
            exempt: 0,
            number: 9892,
            prefix: "T003",
            subtotal: null,
            tax: 0,
            tax_percent: 0,
            total: 0,
            created: "2023-04-17T15:08:05.303521-04:00",
            updated: "2023-04-17T15:08:05.303540-04:00",
            collection_method: null,
            custom_id: null,
            due_date: "2023-04-14",
            imported: false,
            note: null,
            period_start: null,
            period_end: null,
            document_type: "PE09",
            taxpayer: {
                tax_id: "20602170404",
                name: "PERU VISUAL T.DIAGNOSTIGRX SRL",
                address: "",
                state: "",
                phone: "",
                email: "",
                irs_settings: {},
                country: "PE"
            },
            reference_related: []
        }
    },
    {
        user: "nhamann@axteroid.com",
        auto: false,
        subject: "test32",
        recipient: [
            "nhamann@axteroid.com"
        ],
        text: "Estimado cliente, PERU VISUAL T.DIAGNOSTIGRX SRL,\n\nAdjunto Guía de remisión remitente N° T003 - 9892, correspondiente al 14 abr. 2023 por un monto de 0 PEN \n\nAtentamente REPRESENTACIONES DECO S.A.C",
        created: "2023-04-17T19:50:33.809503-04:00",
        document: {
            id: "do_UuGPiAAAAYxaE7gr",
            customer: {
                id: "cus_TTMWS2hYdZv2rn6j8L",
                address: "",
                allow_pdf: true,
                allow_xml: true,
                email: "",
                metadata: {},
                name: "CORPORACION BOTICAS PERU S.A.C.",
                phone: null,
                state: "",
                business_activity: "",
                created: "2023-05-23T16:19:06.863111-04:00",
                updated: "2023-05-23T16:19:06.863134-04:00",
                taxpayer: {
                    tax_id: "20515346113",
                    name: "BOTICAS PERU",
                    address: "",
                    state: "",
                    phone: "",
                    email: "",
                    irs_settings: {},
                    country: "PE"
                }
            },
            metadata: {
                recipient: [
                    "rayosxsolution@gmail.com"
                ]
            },
            shipping: {
                address: "",
                state: "",
                carrier_id: "74751609",
                carrier_name: "WILSON GONZALES TOCTO",
                licence_plate: "VRQ318",
                type: "01"
            },
            status: {
                code: "INI",
                detail: "POS file recieved.",
                irs_id: ""
            },
            exchange: {},
            labels: [],
            amount: 0,
            date: "2023-06-19",
            discount: 0,
            discount_scheme: null,
            exempt: 0,
            number: 9892,
            prefix: "T003",
            subtotal: null,
            tax: 0,
            tax_percent: 0,
            total: 0,
            created: "2023-04-17T15:08:05.303521-04:00",
            updated: "2023-04-17T15:08:05.303540-04:00",
            collection_method: null,
            custom_id: null,
            due_date: "2023-04-14",
            imported: false,
            note: null,
            period_start: null,
            period_end: null,
            document_type: "PE09",
            taxpayer: {
                tax_id: "20602170404",
                name: "PERU VISUAL T.DIAGNOSTIGRX SRL",
                address: "",
                state: "",
                phone: "",
                email: "",
                irs_settings: {},
                country: "PE"
            },
            reference_related: []
        }
    },
    {
        user: "nhamann@axteroid.com",
        auto: false,
        subject: "asdfasfasf sadfasfasfdasdf",
        recipient: [
            "nhamann@axteroid.com"
        ],
        text: "Estimado cliente, PERU VISUAL T.DIAGNOSTIGRX SRL,\n\nAdjunto Guía de remisión remitente N° T003 - 9892, correspondiente al 14 abr. 2023 por un monto de 0 PEN \n\nAtentamente REPRESENTACIONES DECO S.A.C",
        created: "2023-04-17T19:48:28.850376-04:00",
        document: {
            id: "do_UuGPiAAAAYxaE7gr",
            customer: {
                id: "cus_TTMWS2hYdZv2rn6j8L",
                address: "",
                allow_pdf: true,
                allow_xml: true,
                email: "",
                metadata: {},
                name: "CORPORACION BOTICAS PERU S.A.C.",
                phone: null,
                state: "",
                business_activity: "",
                created: "2023-05-23T16:19:06.863111-04:00",
                updated: "2023-05-23T16:19:06.863134-04:00",
                taxpayer: {
                    tax_id: "20515346113",
                    name: "BOTICAS PERU",
                    address: "",
                    state: "",
                    phone: "",
                    email: "",
                    irs_settings: {},
                    country: "PE"
                }
            },
            metadata: {
                recipient: [
                    "rayosxsolution@gmail.com"
                ]
            },
            shipping: {
                address: "",
                state: "",
                carrier_id: "74751609",
                carrier_name: "WILSON GONZALES TOCTO",
                licence_plate: "VRQ318",
                type: "01"
            },
            status: {
                code: "INI",
                detail: "POS file recieved.",
                irs_id: ""
            },
            exchange: {},
            labels: [],
            amount: 0,
            date: "2023-06-19",
            discount: 0,
            discount_scheme: null,
            exempt: 0,
            number: 9892,
            prefix: "T003",
            subtotal: null,
            tax: 0,
            tax_percent: 0,
            total: 0,
            created: "2023-04-17T15:08:05.303521-04:00",
            updated: "2023-04-17T15:08:05.303540-04:00",
            collection_method: null,
            custom_id: null,
            due_date: "2023-04-14",
            imported: false,
            note: null,
            period_start: null,
            period_end: null,
            document_type: "PE09",
            taxpayer: {
                tax_id: "20602170404",
                name: "PERU VISUAL T.DIAGNOSTIGRX SRL",
                address: "",
                state: "",
                phone: "",
                email: "",
                irs_settings: {},
                country: "PE"
            },
            reference_related: []
        }
    },
    {
        user: "nhamann@axteroid.com",
        auto: false,
        subject: "nhamann@axteroid.com",
        recipient: [
            "nhamann@axteroid.com"
        ],
        text: "Estimado cliente, PERU VISUAL T.DIAGNOSTIGRX SRL,\n\nAdjunto Guía de remisión remitente N° T003 - 9892, correspondiente al 14 abr. 2023 por un monto de 0 PEN \n\nAtentamente REPRESENTACIONES DECO S.A.C",
        created: "2023-04-17T19:45:15.507750-04:00",
        document: {
            id: "do_UuGPiAAAAYxaE7gr",
            customer: {
                id: "cus_TTMWS2hYdZv2rn6j8L",
                address: "",
                allow_pdf: true,
                allow_xml: true,
                email: "",
                metadata: {},
                name: "CORPORACION BOTICAS PERU S.A.C.",
                phone: null,
                state: "",
                business_activity: "",
                created: "2023-05-23T16:19:06.863111-04:00",
                updated: "2023-05-23T16:19:06.863134-04:00",
                taxpayer: {
                    tax_id: "20515346113",
                    name: "BOTICAS PERU",
                    address: "",
                    state: "",
                    phone: "",
                    email: "",
                    irs_settings: {},
                    country: "PE"
                }
            },
            metadata: {
                recipient: [
                    "rayosxsolution@gmail.com"
                ]
            },
            shipping: {
                address: "",
                state: "",
                carrier_id: "74751609",
                carrier_name: "WILSON GONZALES TOCTO",
                licence_plate: "VRQ318",
                type: "01"
            },
            status: {
                code: "INI",
                detail: "POS file recieved.",
                irs_id: ""
            },
            exchange: {},
            labels: [],
            amount: 0,
            date: "2023-06-19",
            discount: 0,
            discount_scheme: null,
            exempt: 0,
            number: 9892,
            prefix: "T003",
            subtotal: null,
            tax: 0,
            tax_percent: 0,
            total: 0,
            created: "2023-04-17T15:08:05.303521-04:00",
            updated: "2023-04-17T15:08:05.303540-04:00",
            collection_method: null,
            custom_id: null,
            due_date: "2023-04-14",
            imported: false,
            note: null,
            period_start: null,
            period_end: null,
            document_type: "PE09",
            taxpayer: {
                tax_id: "20602170404",
                name: "PERU VISUAL T.DIAGNOSTIGRX SRL",
                address: "",
                state: "",
                phone: "",
                email: "",
                irs_settings: {},
                country: "PE"
            },
            reference_related: []
        }
    },
    {
        user: "nhamann@axteroid.com",
        auto: false,
        subject: "dfadfasdffafdasdfasdfasfdasdf",
        recipient: [
            "nhamann@axteroid.com"
        ],
        text: "Estimado cliente, PERU VISUAL T.DIAGNOSTIGRX SRL,\n\nAdjunto Guía de remisión remitente N° T003 - 9892, correspondiente al 14 abr. 2023 por un monto de 0 PEN \n\nAtentamente REPRESENTACIONES DECO S.A.C",
        created: "2023-04-17T19:34:53.965614-04:00",
        document: {
            id: "do_UuGPiAAAAYxaE7gr",
            customer: {
                id: "cus_TTMWS2hYdZv2rn6j8L",
                address: "",
                allow_pdf: true,
                allow_xml: true,
                email: "",
                metadata: {},
                name: "CORPORACION BOTICAS PERU S.A.C.",
                phone: null,
                state: "",
                business_activity: "",
                created: "2023-05-23T16:19:06.863111-04:00",
                updated: "2023-05-23T16:19:06.863134-04:00",
                taxpayer: {
                    tax_id: "20515346113",
                    name: "BOTICAS PERU",
                    address: "",
                    state: "",
                    phone: "",
                    email: "",
                    irs_settings: {},
                    country: "PE"
                }
            },
            metadata: {
                recipient: [
                    "rayosxsolution@gmail.com"
                ]
            },
            shipping: {
                address: "",
                state: "",
                carrier_id: "74751609",
                carrier_name: "WILSON GONZALES TOCTO",
                licence_plate: "VRQ318",
                type: "01"
            },
            status: {
                code: "INI",
                detail: "POS file recieved.",
                irs_id: ""
            },
            exchange: {},
            labels: [],
            amount: 0,
            date: "2023-06-19",
            discount: 0,
            discount_scheme: null,
            exempt: 0,
            number: 9892,
            prefix: "T003",
            subtotal: null,
            tax: 0,
            tax_percent: 0,
            total: 0,
            created: "2023-04-17T15:08:05.303521-04:00",
            updated: "2023-04-17T15:08:05.303540-04:00",
            collection_method: null,
            custom_id: null,
            due_date: "2023-04-14",
            imported: false,
            note: null,
            period_start: null,
            period_end: null,
            document_type: "PE09",
            taxpayer: {
                tax_id: "20602170404",
                name: "PERU VISUAL T.DIAGNOSTIGRX SRL",
                address: "",
                state: "",
                phone: "",
                email: "",
                irs_settings: {},
                country: "PE"
            },
            reference_related: []
        }
    },
    {
        user: "nhamann@axteroid.com",
        auto: false,
        subject: "test222",
        recipient: [
            "nhamann@axteroid.com"
        ],
        text: "Estimado cliente, PERU VISUAL T.DIAGNOSTIGRX SRL,\n\nAdjunto Guía de remisión remitente N° T003 - 9892, correspondiente al 14 abr. 2023 por un monto de 0 PEN \n\nAtentamente REPRESENTACIONES DECO S.A.C",
        created: "2023-04-17T19:33:47.231484-04:00",
        document: {
            id: "do_UuGPiAAAAYxaE7gr",
            customer: {
                id: "cus_TTMWS2hYdZv2rn6j8L",
                address: "",
                allow_pdf: true,
                allow_xml: true,
                email: "",
                metadata: {},
                name: "CORPORACION BOTICAS PERU S.A.C.",
                phone: null,
                state: "",
                business_activity: "",
                created: "2023-05-23T16:19:06.863111-04:00",
                updated: "2023-05-23T16:19:06.863134-04:00",
                taxpayer: {
                    tax_id: "20515346113",
                    name: "BOTICAS PERU",
                    address: "",
                    state: "",
                    phone: "",
                    email: "",
                    irs_settings: {},
                    country: "PE"
                }
            },
            metadata: {
                recipient: [
                    "rayosxsolution@gmail.com"
                ]
            },
            shipping: {
                address: "",
                state: "",
                carrier_id: "74751609",
                carrier_name: "WILSON GONZALES TOCTO",
                licence_plate: "VRQ318",
                type: "01"
            },
            status: {
                code: "INI",
                detail: "POS file recieved.",
                irs_id: ""
            },
            exchange: {},
            labels: [],
            amount: 0,
            date: "2023-06-19",
            discount: 0,
            discount_scheme: null,
            exempt: 0,
            number: 9892,
            prefix: "T003",
            subtotal: null,
            tax: 0,
            tax_percent: 0,
            total: 0,
            created: "2023-04-17T15:08:05.303521-04:00",
            updated: "2023-04-17T15:08:05.303540-04:00",
            collection_method: null,
            custom_id: null,
            due_date: "2023-04-14",
            imported: false,
            note: null,
            period_start: null,
            period_end: null,
            document_type: "PE09",
            taxpayer: {
                tax_id: "20602170404",
                name: "PERU VISUAL T.DIAGNOSTIGRX SRL",
                address: "",
                state: "",
                phone: "",
                email: "",
                irs_settings: {},
                country: "PE"
            },
            reference_related: []
        }
    },
    {
        user: "nhamann@axteroid.com",
        auto: false,
        subject: "testtest",
        recipient: [
            "nhamann@axteroid.com"
        ],
        text: "Estimado cliente, PERU VISUAL T.DIAGNOSTIGRX SRL,\n\nAdjunto Guía de remisión remitente N° T003 - 9892, correspondiente al 14 abr. 2023 por un monto de 0 PEN \n\nAtentamente REPRESENTACIONES DECO S.A.C",
        created: "2023-04-17T19:30:58.254345-04:00",
        document: {
            id: "do_UuGPiAAAAYxaE7gr",
            customer: {
                id: "cus_TTMWS2hYdZv2rn6j8L",
                address: "",
                allow_pdf: true,
                allow_xml: true,
                email: "",
                metadata: {},
                name: "CORPORACION BOTICAS PERU S.A.C.",
                phone: null,
                state: "",
                business_activity: "",
                created: "2023-05-23T16:19:06.863111-04:00",
                updated: "2023-05-23T16:19:06.863134-04:00",
                taxpayer: {
                    tax_id: "20515346113",
                    name: "BOTICAS PERU",
                    address: "",
                    state: "",
                    phone: "",
                    email: "",
                    irs_settings: {},
                    country: "PE"
                }
            },
            metadata: {
                recipient: [
                    "rayosxsolution@gmail.com"
                ]
            },
            shipping: {
                address: "",
                state: "",
                carrier_id: "74751609",
                carrier_name: "WILSON GONZALES TOCTO",
                licence_plate: "VRQ318",
                type: "01"
            },
            status: {
                code: "INI",
                detail: "POS file recieved.",
                irs_id: ""
            },
            exchange: {},
            labels: [],
            amount: 0,
            date: "2023-06-19",
            discount: 0,
            discount_scheme: null,
            exempt: 0,
            number: 9892,
            prefix: "T003",
            subtotal: null,
            tax: 0,
            tax_percent: 0,
            total: 0,
            created: "2023-04-17T15:08:05.303521-04:00",
            updated: "2023-04-17T15:08:05.303540-04:00",
            collection_method: null,
            custom_id: null,
            due_date: "2023-04-14",
            imported: false,
            note: null,
            period_start: null,
            period_end: null,
            document_type: "PE09",
            taxpayer: {
                tax_id: "20602170404",
                name: "PERU VISUAL T.DIAGNOSTIGRX SRL",
                address: "",
                state: "",
                phone: "",
                email: "",
                irs_settings: {},
                country: "PE"
            },
            reference_related: []
        }
    },
    {
        user: "nhamann@axteroid.com",
        auto: false,
        subject: "Envía Guía de remisión remitente N° T003 - 9892",
        recipient: [
            "nhamann@axteroid.com"
        ],
        text: "Estimado cliente, PERU VISUAL T.DIAGNOSTIGRX SRL,\n\nAdjunto Guía de remisión remitente N° T003 - 9892, correspondiente al 14 abr. 2023 por un monto de 0 PEN \n\nAtentamente REPRESENTACIONES DECO S.A.C",
        created: "2023-04-17T19:27:58.049141-04:00",
        document: {
            id: "do_UuGPiAAAAYxaE7gr",
            customer: {
                id: "cus_TTMWS2hYdZv2rn6j8L",
                address: "",
                allow_pdf: true,
                allow_xml: true,
                email: "",
                metadata: {},
                name: "CORPORACION BOTICAS PERU S.A.C.",
                phone: null,
                state: "",
                business_activity: "",
                created: "2023-05-23T16:19:06.863111-04:00",
                updated: "2023-05-23T16:19:06.863134-04:00",
                taxpayer: {
                    tax_id: "20515346113",
                    name: "BOTICAS PERU",
                    address: "",
                    state: "",
                    phone: "",
                    email: "",
                    irs_settings: {},
                    country: "PE"
                }
            },
            metadata: {
                recipient: [
                    "rayosxsolution@gmail.com"
                ]
            },
            shipping: {
                address: "",
                state: "",
                carrier_id: "74751609",
                carrier_name: "WILSON GONZALES TOCTO",
                licence_plate: "VRQ318",
                type: "01"
            },
            status: {
                code: "INI",
                detail: "POS file recieved.",
                irs_id: ""
            },
            exchange: {},
            labels: [],
            amount: 0,
            date: "2023-06-19",
            discount: 0,
            discount_scheme: null,
            exempt: 0,
            number: 9892,
            prefix: "T003",
            subtotal: null,
            tax: 0,
            tax_percent: 0,
            total: 0,
            created: "2023-04-17T15:08:05.303521-04:00",
            updated: "2023-04-17T15:08:05.303540-04:00",
            collection_method: null,
            custom_id: null,
            due_date: "2023-04-14",
            imported: false,
            note: null,
            period_start: null,
            period_end: null,
            document_type: "PE09",
            taxpayer: {
                tax_id: "20602170404",
                name: "PERU VISUAL T.DIAGNOSTIGRX SRL",
                address: "",
                state: "",
                phone: "",
                email: "",
                irs_settings: {},
                country: "PE"
            },
            reference_related: []
        }
    },
    {
        user: "nhamann@axteroid.com",
        auto: false,
        subject: "asdfasfdasdf sadfasdfasdf",
        recipient: [
            "nhamann@axteroid.com"
        ],
        text: "Estimado cliente, PERU VISUAL T.DIAGNOSTIGRX SRL,\n\nAdjunto Guía de remisión remitente N° T003 - 9892, correspondiente al 14 abr. 2023 por un monto de 0 PEN \n\nAtentamente REPRESENTACIONES DECO S.A.C",
        created: "2023-04-17T19:08:30.037945-04:00",
        document: {
            id: "do_UuGPiAAAAYxaE7gr",
            customer: {
                id: "cus_TTMWS2hYdZv2rn6j8L",
                address: "",
                allow_pdf: true,
                allow_xml: true,
                email: "",
                metadata: {},
                name: "CORPORACION BOTICAS PERU S.A.C.",
                phone: null,
                state: "",
                business_activity: "",
                created: "2023-05-23T16:19:06.863111-04:00",
                updated: "2023-05-23T16:19:06.863134-04:00",
                taxpayer: {
                    tax_id: "20515346113",
                    name: "BOTICAS PERU",
                    address: "",
                    state: "",
                    phone: "",
                    email: "",
                    irs_settings: {},
                    country: "PE"
                }
            },
            metadata: {
                recipient: [
                    "rayosxsolution@gmail.com"
                ]
            },
            shipping: {
                address: "",
                state: "",
                carrier_id: "74751609",
                carrier_name: "WILSON GONZALES TOCTO",
                licence_plate: "VRQ318",
                type: "01"
            },
            status: {
                code: "INI",
                detail: "POS file recieved.",
                irs_id: ""
            },
            exchange: {},
            labels: [],
            amount: 0,
            date: "2023-06-19",
            discount: 0,
            discount_scheme: null,
            exempt: 0,
            number: 9892,
            prefix: "T003",
            subtotal: null,
            tax: 0,
            tax_percent: 0,
            total: 0,
            created: "2023-04-17T15:08:05.303521-04:00",
            updated: "2023-04-17T15:08:05.303540-04:00",
            collection_method: null,
            custom_id: null,
            due_date: "2023-04-14",
            imported: false,
            note: null,
            period_start: null,
            period_end: null,
            document_type: "PE09",
            taxpayer: {
                tax_id: "20602170404",
                name: "PERU VISUAL T.DIAGNOSTIGRX SRL",
                address: "",
                state: "",
                phone: "",
                email: "",
                irs_settings: {},
                country: "PE"
            },
            reference_related: []
        }
    },
    {
        user: "nhamann@axteroid.com",
        auto: false,
        subject: "tetest",
        recipient: [
            "nhamann@axteroid.com"
        ],
        text: "Estimado cliente, PERU VISUAL T.DIAGNOSTIGRX SRL,\n\nAdjunto Guía de remisión remitente N° T003 - 9892, correspondiente al 14 abr. 2023 por un monto de 0 PEN \n\nAtentamente REPRESENTACIONES DECO S.A.C",
        created: "2023-04-17T18:45:13.669127-04:00",
        document: {
            id: "do_UuGPiAAAAYxaE7gr",
            customer: {
                id: "cus_TTMWS2hYdZv2rn6j8L",
                address: "",
                allow_pdf: true,
                allow_xml: true,
                email: "",
                metadata: {},
                name: "CORPORACION BOTICAS PERU S.A.C.",
                phone: null,
                state: "",
                business_activity: "",
                created: "2023-05-23T16:19:06.863111-04:00",
                updated: "2023-05-23T16:19:06.863134-04:00",
                taxpayer: {
                    tax_id: "20515346113",
                    name: "BOTICAS PERU",
                    address: "",
                    state: "",
                    phone: "",
                    email: "",
                    irs_settings: {},
                    country: "PE"
                }
            },
            metadata: {
                recipient: [
                    "rayosxsolution@gmail.com"
                ]
            },
            shipping: {
                address: "",
                state: "",
                carrier_id: "74751609",
                carrier_name: "WILSON GONZALES TOCTO",
                licence_plate: "VRQ318",
                type: "01"
            },
            status: {
                code: "INI",
                detail: "POS file recieved.",
                irs_id: ""
            },
            exchange: {},
            labels: [],
            amount: 0,
            date: "2023-06-19",
            discount: 0,
            discount_scheme: null,
            exempt: 0,
            number: 9892,
            prefix: "T003",
            subtotal: null,
            tax: 0,
            tax_percent: 0,
            total: 0,
            created: "2023-04-17T15:08:05.303521-04:00",
            updated: "2023-04-17T15:08:05.303540-04:00",
            collection_method: null,
            custom_id: null,
            due_date: "2023-04-14",
            imported: false,
            note: null,
            period_start: null,
            period_end: null,
            document_type: "PE09",
            taxpayer: {
                tax_id: "20602170404",
                name: "PERU VISUAL T.DIAGNOSTIGRX SRL",
                address: "",
                state: "",
                phone: "",
                email: "",
                irs_settings: {},
                country: "PE"
            },
            reference_related: []
        }
    },
    {
        user: "nhamann@axteroid.com",
        auto: false,
        subject: "asdfasdfafasfdasdfasfdsafdasf",
        recipient: [
            "nhamann@axteroid.com"
        ],
        text: "Estimado cliente, PERU VISUAL T.DIAGNOSTIGRX SRL,\n\nAdjunto Guía de remisión remitente N° T003 - 9892, correspondiente al 14 abr. 2023 por un monto de 0 PEN \n\nAtentamente REPRESENTACIONES DECO S.A.C",
        created: "2023-04-17T18:29:07.286054-04:00",
        document: {
            id: "do_UuGPiAAAAYxaE7gr",
            customer: {
                id: "cus_TTMWS2hYdZv2rn6j8L",
                address: "",
                allow_pdf: true,
                allow_xml: true,
                email: "",
                metadata: {},
                name: "CORPORACION BOTICAS PERU S.A.C.",
                phone: null,
                state: "",
                business_activity: "",
                created: "2023-05-23T16:19:06.863111-04:00",
                updated: "2023-05-23T16:19:06.863134-04:00",
                taxpayer: {
                    tax_id: "20515346113",
                    name: "BOTICAS PERU",
                    address: "",
                    state: "",
                    phone: "",
                    email: "",
                    irs_settings: {},
                    country: "PE"
                }
            },
            metadata: {
                recipient: [
                    "rayosxsolution@gmail.com"
                ]
            },
            shipping: {
                address: "",
                state: "",
                carrier_id: "74751609",
                carrier_name: "WILSON GONZALES TOCTO",
                licence_plate: "VRQ318",
                type: "01"
            },
            status: {
                code: "INI",
                detail: "POS file recieved.",
                irs_id: ""
            },
            exchange: {},
            labels: [],
            amount: 0,
            date: "2023-06-19",
            discount: 0,
            discount_scheme: null,
            exempt: 0,
            number: 9892,
            prefix: "T003",
            subtotal: null,
            tax: 0,
            tax_percent: 0,
            total: 0,
            created: "2023-04-17T15:08:05.303521-04:00",
            updated: "2023-04-17T15:08:05.303540-04:00",
            collection_method: null,
            custom_id: null,
            due_date: "2023-04-14",
            imported: false,
            note: null,
            period_start: null,
            period_end: null,
            document_type: "PE09",
            taxpayer: {
                tax_id: "20602170404",
                name: "PERU VISUAL T.DIAGNOSTIGRX SRL",
                address: "",
                state: "",
                phone: "",
                email: "",
                irs_settings: {},
                country: "PE"
            },
            reference_related: []
        }
    },
    {
        user: "nhamann@axteroid.com",
        auto: false,
        subject: "Envía Guía de remisión remitente N° T003 - 9892",
        recipient: [
            "nhamann@axteroid.com"
        ],
        text: "Estimado cliente, PERU VISUAL T.DIAGNOSTIGRX SRL,\n\nAdjunto Guía de remisión remitente N° T003 - 9892, correspondiente al 14 abr. 2023 por un monto de 0 PEN \n\nAtentamente REPRESENTACIONES DECO S.A.C",
        created: "2023-04-17T18:21:18.818085-04:00",
        document: {
            id: "do_UuGPiAAAAYxaE7gr",
            customer: {
                id: "cus_TTMWS2hYdZv2rn6j8L",
                address: "",
                allow_pdf: true,
                allow_xml: true,
                email: "",
                metadata: {},
                name: "CORPORACION BOTICAS PERU S.A.C.",
                phone: null,
                state: "",
                business_activity: "",
                created: "2023-05-23T16:19:06.863111-04:00",
                updated: "2023-05-23T16:19:06.863134-04:00",
                taxpayer: {
                    tax_id: "20515346113",
                    name: "BOTICAS PERU",
                    address: "",
                    state: "",
                    phone: "",
                    email: "",
                    irs_settings: {},
                    country: "PE"
                }
            },
            metadata: {
                recipient: [
                    "rayosxsolution@gmail.com"
                ]
            },
            shipping: {
                address: "",
                state: "",
                carrier_id: "74751609",
                carrier_name: "WILSON GONZALES TOCTO",
                licence_plate: "VRQ318",
                type: "01"
            },
            status: {
                code: "INI",
                detail: "POS file recieved.",
                irs_id: ""
            },
            exchange: {},
            labels: [],
            amount: 0,
            date: "2023-06-19",
            discount: 0,
            discount_scheme: null,
            exempt: 0,
            number: 9892,
            prefix: "T003",
            subtotal: null,
            tax: 0,
            tax_percent: 0,
            total: 0,
            created: "2023-04-17T15:08:05.303521-04:00",
            updated: "2023-04-17T15:08:05.303540-04:00",
            collection_method: null,
            custom_id: null,
            due_date: "2023-04-14",
            imported: false,
            note: null,
            period_start: null,
            period_end: null,
            document_type: "PE09",
            taxpayer: {
                tax_id: "20602170404",
                name: "PERU VISUAL T.DIAGNOSTIGRX SRL",
                address: "",
                state: "",
                phone: "",
                email: "",
                irs_settings: {},
                country: "PE"
            },
            reference_related: []
        }
    }
]